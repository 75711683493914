import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Slider from "react-slick"
import Background from "./hero-banner.jpg"
import { useIntl } from "gatsby-plugin-react-intl"
import bannerIllus from "../blog/banner.webp";

const SearchHero = ({}) => {
  const intl = useIntl()

  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    fade: true,
    speed: 1000,
    lazyLoad: true,
    autoplaySpeed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <section
      className="mainBanner height-small">
      <Slider {...settings}>
        <div className="slider-element">
          <div
            className="mainBanner-infos"
            style={{
              backgroundImage: "linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 75%), url(" +
                  Background +
                  ")",
            }}
          >
            <Container>
              <Row className="justify-content-center alignCenter">
                <Col md={10} xs={12}></Col>
              </Row>
            </Container>
          </div>
        </div>
      </Slider>
      <span className="scrollElem"></span>
    </section>
  )
}

export default SearchHero
